$fade_in_background_color: rgba(color($grey, 1200), 0.65);

@keyframes fade-in-opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.search-bar-dim-background {
    position: absolute;
    left: 0;
    width: 100%;
    background: $black;
    z-index: 99;
    transition: all 0.1s ease-in-out;
}

.search-bar-package {
    .search-form {
        display: flex;
        position: relative;
        height: 42px;

        &.button-inside input {
            border-right-width: 1px;
            border-radius: 12px;
            padding-right: 48px;

            &.pro {
                border-right-width: 1px;
            }

            &:focus {
                border-right-width: 1px;
            }
        }

        input {
            font-size: 16px;
            flex: 1 1 auto;
            padding: 10px 2px 10px 16px;
            border-radius: 4px;
            border-color: color($grey, 500);
            box-shadow: none;
            z-index: 3;

            &.long-placeholder {
                display: none;
            }

            &.pro {
                border-right-width: 0;
                border-radius: 4px 0 0 4px;
            }

            @include media-query-grid(md) {
                border-right-width: 0;
                border-radius: 4px 0 0 4px;

                &.short-placeholder {
                    display: none;
                }

                &.long-placeholder {
                    display: block;
                }
            }

            &::placeholder {
                color: color($grey, 800);
            }

            &:focus {
                border-color: color($grey, 800);
                box-shadow: none;

                ~ .focused-animation {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: $fade_in_background_color;
                    z-index: 2;
                    animation: fade-in-opacity 0.3s ease-in;
                }

                @include media-query-grid(lg) {
                    border-right-width: 0;
                }
            }

            &::-webkit-search-cancel-button {
                $size: 32px;

                height: $size;
                width: $size;
                appearance: none;
                cursor: pointer;
                background-image: url('../../assets/clear_button_sprite.svg');
                background-position: 0 0;

                &:hover {
                    background-position: $size 0;
                }
            }
        }

        button {
            display: none;
            flex-shrink: 0;
            z-index: 3;
            padding: 8px 16px;

            &.pro {
                border-radius: 0 4px 4px 0;
            }

            @include media-query-grid(md) {
                display: block;
                font-size: 14px;
                text-shadow: none;
                border-radius: 0 4px 4px 0;
            }
        }

        .search-bar-icon {
            position: absolute;
            z-index: 5;
            top: 13px;
            left: 13px;
        }

        &.search-icon-shown {
            input {
                padding-left: 40px;
            }
        }

        &.button-inside {
            height: 52px;

            @include media-query-grid(lg) {
                height: 60px;

                input {
                    padding-right: 58px;
                }
            }
        }

        &.dark {
            input {
                border-color: color($grey, 600);
                color: color($grey, 1000);

                &:focus {
                    border-color: color($grey, 1200);
                    color: color($grey, 1000);
                }
            }
        }
    }

    .routing-search-form {
        input {
            &::-webkit-search-cancel-button {
                appearance: none;
                height: 32px;
                width: 32px;
                cursor: pointer;
                background-image: url('../../assets/clear_button_no_circle.svg');
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
}
